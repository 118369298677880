import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import { Page, PageGetters, getPaginationNumber } from '@/utils/pagination'
import AllergenCodeList from '@/constant/allergen-list-codes'

export default {
  namespaced: true,
  state: {
    AllergenList: [],
    Page: {
      ...Page
    }
  },
  getters: {
    Page: state => PageGetters({ state, stateName: 'Page' }),
    AllergenList: state => state.AllergenList,
    isAllergenDataEmpty: state => state.AllergenList.length === 0
  },
  actions: {
    async getAllergenListAll({ state, commit }, payload) {
      const PageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.Page.number })
      const results = await requestHandler({
        title: 'getAllergenListAll',
        methodName: 'getAllergenListAll',
        method: () => {
          return HTTP.get('Allergen/all', {
            params: {
              pageNumber: PageNumber,
              pageSize: payload.pageSize || state.Page.size
            }
          })
        },
        success: result => {
          commit('SET_ALLERGEN_LIST', result.data)
          return result
        }
      })
      return results
    },
    async getAllergen(state, Id) {
      const results = await requestHandler({
        title: 'getAllergen',
        methodName: 'getAllergen',
        method: () => {
          return HTTP.get('Allergen', { params: { Id } })
        },
        success: result => result
      })
      return results
    },
    async createAllergen({ commit }, payload) {
      const results = await requestHandler({
        title: 'createAllergen',
        methodName: 'createAllergen',
        method: () => {
          return HTTP.post('Allergen', payload)
        },
        success: result => result
      })
      return results
    },
    async updateAllergen({ commit }, payload) {
      const results = await requestHandler({
        title: 'updateAllergen',
        methodName: 'updateAllergen',
        method: () => {
          return HTTP.put('Allergen', payload)
        },
        success: result => result
      })
      return results
    },
    async deleteAllergen(commit, Id) {
      const results = await requestHandler({
        title: 'deleteAllergen',
        methodName: 'deleteAllergen',
        method: () => {
          return HTTP.delete('Allergen', { params: { Id } })
        },
        success: result => result
      })
      return results
    }
  },
  mutations: {
    SET_ALLERGEN_LIST(state, data) {
      const allergenData = data.allergenList.map(el => {
        let iconMapping = AllergenCodeList.find(iconCode => iconCode.code === el.code)
        return {
          ...el,
          iconName: iconMapping ? iconMapping.icon : 'simpra-logo'
        }
      })
      state.AllergenList = allergenData
      state.Page.number = data.pageNumber
      state.Page.size = data.pageSize
      state.Page.total = data.totalCount
    }
  }
}
