export default function($t) {
  return [
    {
      name: 'symbol',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('Allergen.symbol')
      },
      get title() {
        return $t('Allergen.symbol')
      }
    },

    {
      name: 'name',
      is_visible: true,
      headerTitle: true,
      get short_title() {
        return $t('Allergen.table_Header_field_name')
      },
      get title() {
        return $t('Allergen.table_Header_field_name')
      }
    },

    {
      name: 'description',
      is_visible: true,
      get short_title() {
        return $t('Allergen.formFieldDescription')
      },
      get title() {
        return $t('Allergen.formFieldDescription')
      }
    }
  ]
}
