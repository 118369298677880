export default function($t) {
  return [
    {
      name: 'foodGroupName',
      is_visible: true,
      type: 'portal',
      get short_title() {
        return $t('NutritionalValues.foodGroup')
      },
      get title() {
        return $t('NutritionalValues.foodGroup')
      },
      is_sortable: true,
      sortingObject: {
        columnName: 'NutritionGroupName',
        sortType: null
      },
      filter: {
        showFilter: false,
        position: {},
        list: [],
        selectedList: [],
        selectedListName: 'selectedNutritionGroups',
        is_visible: true,
        type: null,
        headerLabel: $t('NutritionalValues.filter_FoodGroup'),
        isClearFilterAvailable: false
      }
    },

    {
      name: 'nutritionFoodName',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('NutritionalValues.nutritionFoodName')
      },
      get title() {
        return $t('NutritionalValues.nutritionFoodName')
      },
      is_sortable: true,
      sortingObject: {
        columnName: 'NutritionFoodName',
        sortType: null
      },
      filter: {
        showFilter: false,
        position: {},
        list: [],
        selectedList: [],
        selectedListName: 'selectedNutritions',
        is_visible: true,
        type: null,
        headerLabel: $t('NutritionalValues.filter_FoodName'),
        backendSearch: 'nutritionItem',
        isClearFilterAvailable: true
      }
    },
    {
      name: 'stockItemList',
      type: 'portal',
      is_visible: true,
      get short_title() {
        return $t('NutritionalValues.stockItems')
      },
      get title() {
        return $t('NutritionalValues.stockItems')
      }
    }
  ]
}
