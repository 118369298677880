<template lang="pug">
DeleteAlert(
  :item="info",
  :fields="fields",
  :tableName="$t('NutritionalValues.nameWithPrefix')",
  loading="itemRemoveCustomNutrition",
  @delegateOnAlertRemoveOk="itemRemove")

</template>
<script>
import { mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {

  name: 'CustomNutritionRemove',
  data () {
    return {
      info: {},
      fields: [{
        name: 'nutritionFoodName',
        is_visible: true,
        short_title: this.$t('NutritionalValues.name'),
        title: this.$t('NutritionalValues.name')
      }]
    }
  },

  async mounted () {
    this.info = {
      nutritionFoodName: this.$route.params.nutritionFoodName
    }
  },

  methods: {
    ...mapActions('NutritionalValues', [
      'deleteCustomNutrition',
      'getAllNutritionalValues'
    ]),
    ...mapActions('Notify', ['notifyShow']),
    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    itemRemove: vueWaitLoader(async function () {
      const res = await this.deleteCustomNutrition(this.$route.params.id)
      if (res) {
        const message = this.$t('Global.successRemoveMessage', { field: this.$t('NutritionalValues.name') })
        this.notifyShow({ message })
        this.closePopup()
        await this.getAllNutritionalValues({
          pageNumber: 1
        })
      }
    }, 'itemRemoveCustomNutrition')
  }
}
</script>
