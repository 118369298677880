<template lang="pug">
blured-background
    .Alert
      .Alert-body
        figure.Alert-icon
          Icon(name="icon-global-copy")
        h3.Alert-title {{$t('NutritionalValues.copy') }}
        p.Alert-description
          template {{$t('NutritionalValues.copy_nutrition_food') }}

        .Alert-item
          .Alert-item-name
            span(v-if="item") {{item.nutritionFoodName}}
            Loading(v-else)
        #form-area

          .Custom-food
            .Custom-food-title.m-bottom-15
              span {{$t('NutritionalValues.newNutritionFoodName') }}
            .Custom-food-name
              customTextInput(
                id="input-user-groups-popup-field-name"
                v-model="newNutritionFoodName"
                v-validate="'required|max:250'"
                name="name"
                :data-vv-as="$t('NutritionalValues.popupForm_Field_Name')"
                :error="veeErrors.has('name')"
              )

          showValidateError(
            v-show="veeErrors.has('name')"
            :errorName="veeErrors.first('name')"
          )

        .Alert-actions
          Button(
          secondary
          size="medium"
          id="btn-copy-alert-close"
          type="button",
          @click="close"
          ) {{ $t('Recipes.copyAlert_CancelButton') }}
          Button(
          primary
          size="medium"
          variant="full"
          id="btn-copy-alert-submit"
          form="form-copy-nutrition",
          type="submit",
          @click="copyValue"
          :disabled="isLoadingSubmit")
            span(v-show="!isLoadingSubmit") {{ $t('Recipes.copyAlert_CopyButton') }}
            Loading(theme="disable", v-show="isLoadingSubmit")
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import bluredBackground from '@/view/global/full-blur-background'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'NutritionFoodCopy',

  components: {
    bluredBackground
  },

  data () {
    return {
      item: {},
      newNutritionFoodName: null
    }
  },

  computed: {
    isLoadingSubmit () {
      return this.$wait.is(['submitCopy'])
    },
    ...mapGetters('NutritionalValues', ['Page'])
  },

  created () {
    this.item = this.$route.params
  },

  methods: {
    ...mapActions('Notify', [
      'notifyShow'
    ]),

    ...mapActions('NutritionalValues', [
      'copyNutritionValue', 'getAllNutritionalValues'
    ]),

    close () {
      this.$router.push({
        path: this.$route.matched[0].path
      })
    },

    copyValue: vueWaitLoader(async function () {
      const payload = {
        nutritionFoodId: this.item.id,
        name: this.newNutritionFoodName,
        isCustomFood: this.item.isCustomFood
      }

      await this.copyNutritionValue(payload).then(res => {
        if (res) {
          const message = this.$t('NutritionalValues.alert_Copy_Submitted', {
            name: payload.name
          })
          this.close()
          this.getAllNutritionalValues({
            pageNumber: this.Page.last
          })
          this.notifyShow({ message })
        }
      })
    }, 'submitCopy')
  }
}
</script>

<style lang="scss" scoped>

  .Alert {

    &-body {
      text-align: center;
    }

    &-icon {
      margin-bottom: 30px;
    }

    &-title {
      font-size: 1.6em;
    }

    &-description {
      font-size: 1.2em;
      margin-top: 7px;
      color: $color-text-light;
      &-foodName {
        font-weight: $font-weight-bold;
      }
    }

    &-input {
      display: flex;
      flex-direction: column;
      align-items: center;

    &-search {
      width: 100%;
    }

      &-span {
        color: $color-light;
        font-family: $font-family;
        opacity: 0.72;
      }

      &-span.search-span {
        align-self: flex-start;
        margin-bottom: 10px;
      }

      &-entry {
        width: 276px;
        height: 54px;
        opacity: 0.72;
        font-family: $font-family;
        font-size: $font-size-bigger;
        border-radius: $border-radius;
        border: 1px solid $color-gray;
        color: $color-dark;
        text-align: center;
        margin-top: 13px;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
      margin-top: 50px;

      &-name {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
        padding: 10px;
        min-width: 276px;
        height: 83px;
        border-radius: $border-high-radius;
        box-shadow: 0 2px 15px 0 rgba(38, 166, 253, 0.28);
        background-color: #ffffff;

        span {
          color: $color-dark;
          font-family: $font-family;
          font-size: $font-size-bigger;
          font-weight: $font-weight-bold;
        }
      }
    }

    &-actions {
      margin-left: auto;
      margin-right: auto;
      max-width: 500px;
      margin-top: 25px;
      display: flex;
      align-items: center;
      z-index: 20;

      .btn {
        padding-left: 32px;
        padding-right: 33px;
        margin-left: 10px;
        margin-right: 10px;
        opacity: 0.72;
        transition: opacity 0.2s ease-in-out;
        text-transform: uppercase;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

.icon-global-copy {
  color: $color-success;
  opacity: 1;
  width: 46px;
  height: 56px;
}
.is-not-valid {
  border: 1px solid $color-warning;
  border-radius: $border-radius;
  outline-color: $color-warning;
}
.Custom-food {
  display: flex;
  flex-direction: column;
  &-title {
    font-size: 18px;
    font-weight: initial;
  }
  &-name {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    padding: 10px;
    min-width: 276px;
    height: 83px;
    border-radius: $border-high-radius;
    box-shadow: 0 2px 15px 0 rgba(38, 166, 253, 0.28);
    background-color: #ffffff;
  }

}
</style>
