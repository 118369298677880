import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import { Page, PageGetters, getPaginationNumber } from '@/utils/pagination'

export default {
  namespaced: true,
  state: {
    OptionalFields: [],
    Page: {
      ...Page
    },
    PageLinks: [
      'OptionalFields',
      'Allergen',
      'NutritionalValues'
    ]
  },
  getters: {
    PageLinks: state => state.PageLinks,
    OptionalFields: state => state.OptionalFields,
    IsEmptyFieldsList: state => state.OptionalFields.length === 0,
    Page: state => PageGetters({ state, stateName: 'Page' })
  },
  actions: {
    async fetchOptionsFields({ state, commit }, payload) {
      const PageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.Page.number })
      const results = await requestHandler({
        title: 'fetchOptionsFields',
        methodName: 'fetchOptionsFields',
        method: () => {
          return HTTP.get('Meta/all', {
            params: {
              pageNumber: PageNumber,
              pageSize: payload.pageSize
            }
          })
        },
        success: result => {
          commit('SET_OPTINAL_FIELD', result.data)
          return result
        }
      })
      return results
    },

    async fetchOptionsFieldInfo({ state, commit }, Id) {
      const results = await requestHandler({
        title: 'fetchOptionsFieldInfo',
        methodName: 'fetchOptionsFieldInfo',
        method: () => {
          return HTTP.get('Meta', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    },

    async fetchOptionsFieldByTableType({ state, commit }, tableType) {
      const results = await requestHandler({
        title: 'fetchOptionsFieldByTableType',
        methodName: 'fetchOptionsFieldByTableType',
        method: () => {
          return HTTP.get('Meta/getmetafieldsbytabletype', {
            params: { tableType }
          })
        },
        success: result => result
      })
      return results
    },

    async createOptionalField({ commit }, payload) {
      const results = await requestHandler({
        title: 'createOptionalField',
        methodName: 'createOptionalField',
        method: () => {
          return HTTP.post('Meta', payload)
        },
        success: result => result
      })
      return results
    },

    async updateOptionalField({ commit }, payload) {
      const results = await requestHandler({
        title: 'updateOptionalField',
        methodName: 'updateOptionalField',
        method: () => {
          return HTTP.put('Meta', payload)
        },
        success: result => result
      })
      return results
    },

    async deleteOptionalField({ commit }, Id) {
      const results = await requestHandler({
        title: 'deleteOptionalField',
        methodName: 'deleteOptionalField',
        method: () => {
          return HTTP.delete('Meta', {
            params: { Id }
          })
        },
        success: result => result
      })
      return results
    }

  },
  mutations: {
    SET_OPTINAL_FIELD(state, data) {
      state.Page.number = data.pageNumber
      state.Page.size = data.pageSize
      state.Page.total = data.totalCount
      state.OptionalFields = data.metaList
    }
  }
}
