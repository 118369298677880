var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c("template", { slot: "title" }, [
        _vm.isEdit
          ? _c("div", [
              _vm._v(
                _vm._s(
                  _vm.$t("Global.editData", {
                    field: _vm.$t("OptionalFields.nameWithPrefix"),
                  })
                )
              ),
            ])
          : _c("div", [
              _vm._v(
                _vm._s(
                  _vm.$t("Global.addData", {
                    field: _vm.$t("OptionalFields.name"),
                  })
                )
              ),
            ]),
      ]),
      _c(
        "template",
        { slot: "content" },
        [
          _vm.isFetchingInfo
            ? _c("Loading")
            : _c(
                "form",
                {
                  staticClass: "Form",
                  attrs: { id: "Optinal-Fields-Form" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.onSubmitForm.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(
                        _vm._s(_vm.$t("OptionalFields.field_module_name"))
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control form-item-select" },
                      [
                        _c("Icon", {
                          staticClass: "icon-down-arrow",
                          attrs: { name: "icon-down-arrow" },
                        }),
                        _c("customSelectInput", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required",
                              expression: "'required'",
                            },
                          ],
                          attrs: {
                            name: "moduleTypes",
                            optionData: _vm.ModuleTypes,
                            "data-vv-as": _vm.$t(
                              "OptionalFields.field_module_name"
                            ),
                            error: _vm.veeErrors.has("moduleTypes"),
                            id: "optionalFields-module-list",
                            disabled: _vm.isEdit,
                            optionIdName: "optionalFields-module-list-option",
                            optionName: "label",
                            optionKey: "value",
                            optionIdKey: "value",
                            isValueNumber: true,
                          },
                          model: {
                            value: _vm.form.tableType,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "tableType", $$v)
                            },
                            expression: "form.tableType",
                          },
                        }),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("moduleTypes"),
                              expression: "veeErrors.has('moduleTypes')",
                            },
                          ],
                          attrs: {
                            errorName: _vm.veeErrors.first("moduleTypes"),
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("OptionalFields.field_name"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control" },
                      [
                        _c("customTextInput", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|max:50",
                              expression: "'required|max:50'",
                            },
                          ],
                          attrs: {
                            id: "optionalFields-fieldname",
                            name: "fieldname",
                            "data-vv-as": _vm.$t("OptionalFields.field_name"),
                            error: _vm.veeErrors.has("fieldname"),
                          },
                          model: {
                            value: _vm.form.columnName,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "columnName", $$v)
                            },
                            expression: "form.columnName",
                          },
                        }),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("fieldname"),
                              expression: "veeErrors.has('fieldname')",
                            },
                          ],
                          attrs: {
                            errorName: _vm.veeErrors.first("fieldname"),
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "Form-item required" }, [
                    _c("label", { staticClass: "Form-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("OptionalFields.field_type"))),
                    ]),
                    _c(
                      "div",
                      { staticClass: "control form-item-select" },
                      [
                        _c("Icon", {
                          staticClass: "icon-down-arrow",
                          attrs: { name: "icon-down-arrow" },
                        }),
                        _c("customSelectInput", {
                          directives: [
                            {
                              name: "validate",
                              rawName: "v-validate",
                              value: "required|not_in:-1",
                              expression: "'required|not_in:-1'",
                            },
                          ],
                          attrs: {
                            name: "fieldType",
                            optionData: _vm.inputTypes,
                            "data-vv-as": _vm.$t("OptionalFields.field_type"),
                            error: _vm.veeErrors.has("fieldType"),
                            id: "optionalFields-fieldType-list",
                            disabled: _vm.isEdit,
                            optionIdName:
                              "optionalFields-fieldType-list-option",
                            optionName: "label",
                            optionKey: "value",
                            optionIdKey: "value",
                            isValueNumber: true,
                          },
                          model: {
                            value: _vm.form.columnType,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "columnType", $$v)
                            },
                            expression: "form.columnType",
                          },
                        }),
                        _c("showValidateError", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.veeErrors.has("fieldType"),
                              expression: "veeErrors.has('fieldType')",
                            },
                          ],
                          attrs: {
                            errorName: _vm.veeErrors.first("fieldType"),
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "Form-item" },
                    [
                      !_vm.isFetchingInfo
                        ? _c("CustomInputField", {
                            attrs: {
                              isEdit: _vm.isEdit,
                              type: _vm.form.columnType,
                            },
                            model: {
                              value: _vm.inputProps,
                              callback: function ($$v) {
                                _vm.inputProps = $$v
                              },
                              expression: "inputProps",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "Button",
            {
              staticClass: "uppercase",
              attrs: {
                primary: "",
                size: "large",
                type: "submit",
                variant: "full",
                form: "Optinal-Fields-Form",
                isLoading: _vm.isRunAction,
                disabled: _vm.actionDisabled,
                id: "Optinal-Fields-Form-button",
              },
            },
            [
              _vm.isEdit
                ? _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("Global.editData", {
                          field: _vm.$t("OptionalFields.nameWithPrefix"),
                        })
                      )
                    ),
                  ])
                : _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("Global.addData", {
                          field: _vm.$t("OptionalFields.name"),
                        })
                      )
                    ),
                  ]),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }