<template lang="pug">
Popup
    template(slot="title")
      div(v-if="isEdit") {{ $t('Global.editData', { field: $t('NutritionalValues.nameWithPrefix') }) }}
      div(v-else) {{ $t('Global.addData', { field: $t('NutritionalValues.name') }) }}

    template(slot="content")
      form.Form(
       id="Nutrition-Fields-Form")
        .Form-item.required
          label.Form-item-label {{ $t('NutritionalValues.field_name') }}
          .control.form-item
            customTextInput(
              id="nutritionFields-fieldname"
              v-model="customNutritionName"
              v-validate="'required|max:250'"
              name="customNutritionFoodName"
              :data-vv-as="$t('NutritionalValues.field_name')"
              :error="veeErrors.has('customNutritionFoodName')")

            showValidateError(
              v-show="veeErrors.has('customNutritionFoodName')"
              :errorName="veeErrors.first('customNutritionFoodName')"
            )

    template(slot="footer")
      Button.uppercase(
        primary,
        size="large"
        type="submit"
        variant="full"
        form="NutritionalValues-Fields-Form",
        :isLoading="isRunAction",
        :disabled="!customNutritionName"
        id="NutritionalValues-Fields-Form-button"
        @click="onSubmitForm"
      )
        span(v-if="isEdit") {{ $t('Global.editData', { field: $t('NutritionalValues.nameWithPrefix') }) }}
        span(v-else) {{ $t('Global.addData', { field: $t('NutritionalValues.name') }) }}
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {
  name: 'NutritionForm',
  props: {
    status: {
      default: 'new',
      type: String
    }
  },
  data () {
    return {
      customNutritionName: null
    }
  },

  computed: {
    isEdit () {
      return this.status === 'edit'
    },
    isFetchingInfo () {
      return this.$wait.is('fetchingInfo')
    },
    isRunAction () {
      return this.$wait.is(['createCustomNutrition', 'updateCustomNutrition', 'onSubmitCustomNutrition'])
    },

    ...mapGetters('NutritionalValues', ['Page'])
  },
  methods: {
    ...mapActions('Notify', ['notifyShow']),
    ...mapActions('NutritionalValues', [
      'createCustomNutrition',
      'getAllNutritionalValues',
      'editCustomNutrition',
      'deleteCustomNutrition'
    ]),

    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    async addNewNutritionFood () {
      const payload = {
        name: this.customNutritionName
      }
      const result = await this.createCustomNutrition(payload)
      if (result) {
        const message = this.$t('Global.successCreateMessage', { field: this.$t('NutritionalValues.name') })
        this.notifyShow({ message })
        this.closePopup()
        this.getAllNutritionalValues({
          pageNumber: this.Page.last
        })
      }
    },

    async editCustomNutritionFood () {
      const payload = {
        nutritionFoodId: this.$route.params.id,
        foodName: this.customNutritionName
      }
      const result = await this.editCustomNutrition(payload)
      if (result) {
        const message = this.$t('Global.successUpdateMessage', { field: this.$t('OptionalFields.name') })
        this.notifyShow({ message })
        this.closePopup()
        this.getAllNutritionalValues({
          pageNumber: this.Page.number
        })
      }
    },

    onSubmitForm: vueWaitLoader(async function () {
      if (this.isEdit) {
        await this.editCustomNutritionFood()
      } else {
        await this.addNewNutritionFood()
      }
    }, 'onSubmitCustomNutrition'),

    async getNutritionName () {
      this.customNutritionName = this.$route.params.nutritionFoodName
    }
  },

  async beforeMount () {
    if (this.isEdit) {
      this.$wait.start('fetchingInfo')
      await this.getNutritionName()
      this.$wait.end('fetchingInfo')
    }
  }
}
</script>
