import requestHandler from '@/utils/requestHandler'
import HTTP from '@/utils/http'
import { Page, PageGetters, getPaginationNumber } from '@/utils/pagination'

export default {
  namespaced: true,
  state: {
    FoodGroups: [],
    NutritionFoodValues: [],
    NutritionDetails: [],
    Page: {
      ...Page
    },
    PageLinks: [
      'OptionalFields',
      'Allergen',
      'NutritionalValues'
    ]
  },
  getters: {
    PageLinks: state => state.PageLinks,
    FoodGroups: state => state.FoodGroups,
    NutritionFoodValues: state => state.NutritionFoodValues,
    NutritionDetails: state => state.NutritionDetails,
    Page: state => PageGetters({ state, stateName: 'Page' })
  },
  actions: {
    async getFoodGroups({ state, commit }) {
      const results = await requestHandler({
        title: 'getFoodGroups',
        methodName: 'getFoodGroups',
        method: () => {
          return HTTP.get('nutrition/foodgroup/all')
        },
        success: result => {
          commit('SET_FOOD_GROUP_LIST', result.data)
          return result
        }
      })
      return results
    },
    async getAllNutritionalValues({ state, commit }, payload) {
      const PageNumber = getPaginationNumber({ selectedPage: payload.pageNumber, currentPage: state.Page.number })
      const params = {
        pageNumber: PageNumber,
        pageSize: payload.pageSize,
        sortExpressionList: payload.sortExpressionList,
        foodGroupIds: payload.foodGroupIds,
        nutritionFoodIds: payload.nutritionFoodIds
      }
      const results = await requestHandler({
        title: 'getAllNutritionalValues',
        methodName: 'getAllNutritionalValues',
        method: () => {
          return HTTP.post('Nutrition/food/all', params)
        },
        success: result => {
          commit('SET_NUTRITION_LIST', result.data)
          return result
        }
      })
      return results
    },
    async matchStockItems({ commit }, payload) {
      const results = await requestHandler({
        title: 'matchStockItems',
        methodName: 'matchStockItems',
        method: () => {
          return HTTP.put('Nutrition/food/matching', payload)
        },
        success: result => result
      })
      return results
    },
    async createCustomNutrition({ commit }, payload) {
      const results = await requestHandler({
        title: 'createCustomNutrition',
        methodName: 'createCustomNutrition',
        method: () => {
          return HTTP.post('Nutrition/food/custom', payload)
        },
        success: result => result
      })
      return results
    },
    async copyNutritionValue({ commit }, payload) {
      const results = await requestHandler({
        title: 'copyNutritionValue',
        methodName: 'copyNutritionValue',
        method: () => {
          return HTTP.post('Nutrition/food/clone', payload)
        },
        success: result => result
      })
      return results
    },
    async showNutritionDetail({ commit }, payload) {
      const results = await requestHandler({
        title: 'showNutritionDetail',
        methodName: 'showNutritionDetail',
        method: () => {
          return HTTP.post('Nutrition/food/detail', payload)
        },
        success: result => {
          commit('GET_NUTRITION_DETAILS', result.data)
          return result
        }
      })
      return results
    },
    async updateCustomNutritionDetail({ commit }, payload) {
      const results = await requestHandler({
        title: 'updateCustomNutritionDetail',
        methodName: 'updateCustomNutritionDetail',
        method: () => {
          return HTTP.put('Nutrition/food/custom/detail', payload)
        },
        success: result => result
      })
      return results
    },
    async editCustomNutrition({ commit }, payload) {
      const results = await requestHandler({
        title: 'editCustomNutrition',
        methodName: 'editCustomNutrition',
        method: () => {
          return HTTP.put('Nutrition/food/custom', payload)
        },
        success: result => result
      })
      return results
    },
    async deleteCustomNutrition({ commit }, NutritionFoodId) {
      const results = await requestHandler({
        title: 'deleteCustomNutrition',
        methodName: 'deleteCustomNutrition',
        method: () => {
          return HTTP.delete('Nutrition/food/custom', {
            params: { NutritionFoodId }
          })
        },
        success: result => result
      })
      return results
    }
  },
  mutations: {
    SET_FOOD_GROUP_LIST(state, data) {
      state.FoodGroups = data.foodGroupList
    },
    SET_NUTRITION_LIST(state, data) {
      state.Page.number = data.pageNumber
      state.Page.size = data.pageSize
      state.Page.total = data.totalCount
      state.NutritionFoodValues = data.foodList
    },
    GET_NUTRITION_DETAILS(state, data) {
      state.NutritionDetails = data.nutritionDetailList
    }
  }
}
