export function getModules($t) {
  return [
    {
      value: 1,
      label: $t('OptionalFields.module_stock') // stockitem module
    }
  ]
}

export function getInputTypes($t) {
  return [
    {
      value: 1,
      label: $t('OptionalFields.input_type_textbox') // textbox
    },
    {
      value: 2,
      label: $t('OptionalFields.input_type_checkbox') // checkbox
    },
    {
      value: 3,
      label: $t('OptionalFields.input_type_dropdown') // dropdown
    }
  ]
}
