var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("blured-background", [
    _c("div", { staticClass: "Alert" }, [
      _c("div", { staticClass: "Alert-body" }, [
        _c(
          "figure",
          { staticClass: "Alert-icon" },
          [_c("Icon", { attrs: { name: "icon-global-copy" } })],
          1
        ),
        _c("h3", { staticClass: "Alert-title" }, [
          _vm._v(_vm._s(_vm.$t("NutritionalValues.copy"))),
        ]),
        _c(
          "p",
          { staticClass: "Alert-description" },
          [[_vm._v(_vm._s(_vm.$t("NutritionalValues.copy_nutrition_food")))]],
          2
        ),
        _c("div", { staticClass: "Alert-item" }, [
          _c(
            "div",
            { staticClass: "Alert-item-name" },
            [
              _vm.item
                ? _c("span", [_vm._v(_vm._s(_vm.item.nutritionFoodName))])
                : _c("Loading"),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { attrs: { id: "form-area" } },
          [
            _c("div", { staticClass: "Custom-food" }, [
              _c("div", { staticClass: "Custom-food-title m-bottom-15" }, [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("NutritionalValues.newNutritionFoodName"))
                  ),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "Custom-food-name" },
                [
                  _c("customTextInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|max:250",
                        expression: "'required|max:250'",
                      },
                    ],
                    attrs: {
                      id: "input-user-groups-popup-field-name",
                      name: "name",
                      "data-vv-as": _vm.$t(
                        "NutritionalValues.popupForm_Field_Name"
                      ),
                      error: _vm.veeErrors.has("name"),
                    },
                    model: {
                      value: _vm.newNutritionFoodName,
                      callback: function ($$v) {
                        _vm.newNutritionFoodName = $$v
                      },
                      expression: "newNutritionFoodName",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("showValidateError", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.veeErrors.has("name"),
                  expression: "veeErrors.has('name')",
                },
              ],
              attrs: { errorName: _vm.veeErrors.first("name") },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "Alert-actions" },
          [
            _c(
              "Button",
              {
                attrs: {
                  secondary: "",
                  size: "medium",
                  id: "btn-copy-alert-close",
                  type: "button",
                },
                on: { click: _vm.close },
              },
              [_vm._v(_vm._s(_vm.$t("Recipes.copyAlert_CancelButton")))]
            ),
            _c(
              "Button",
              {
                attrs: {
                  primary: "",
                  size: "medium",
                  variant: "full",
                  id: "btn-copy-alert-submit",
                  form: "form-copy-nutrition",
                  type: "submit",
                  disabled: _vm.isLoadingSubmit,
                },
                on: { click: _vm.copyValue },
              },
              [
                _c(
                  "span",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: !_vm.isLoadingSubmit,
                        expression: "!isLoadingSubmit",
                      },
                    ],
                  },
                  [_vm._v(_vm._s(_vm.$t("Recipes.copyAlert_CopyButton")))]
                ),
                _c("Loading", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isLoadingSubmit,
                      expression: "isLoadingSubmit",
                    },
                  ],
                  attrs: { theme: "disable" },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }