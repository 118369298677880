<template lang="pug">
DeleteAlert(
  :item="info",
  :fields="fields",
  :isGetDataLoading="isSettingData",
  :tableName="$t('OptionalFields.nameWithPrefix')",
  loading="itemRemoveOptionalRemove",
  @delegateOnAlertRemoveOk="itemRemove")

</template>
<script>
import { mapActions } from 'vuex'
import { getModules, getInputTypes } from '../data/index'
import { vueWaitLoader } from '@/utils/baseOperations'

export default {

  name: 'OptinalFieldsRemove',
  data () {
    return {
      info: {},
      fields: [{
        name: 'columnName',
        is_visible: true,
        short_title: this.$t('OptionalFields.field_name'),
        title: this.$t('Dictionary.DocNo')
      },
      {
        name: 'customInput',
        is_visible: true,
        short_title: this.$t('OptionalFields.filed_type_short'),
        title: this.$t('OptionalFields.filed_type_short')
      },
      {
        name: 'moduleName',
        is_visible: true,
        short_title: this.$t('OptionalFields.field_module_name'),
        title: this.$t('OptionalFields.field_module_name')
      }]
    }
  },
  computed: {
    isSettingData () {
      return this.$wait.is('fetchOptionsFieldInfo')
    }
  },
  async mounted () {
    const res = await this.fetchOptionsFieldInfo(this.$route.params.id)
    if (res) {
      this.info = {
        ...res.data,
        customInput: getInputTypes(this.$t).find(input => input.value === res.data.columnType).label,
        moduleName: getModules(this.$t).find(table => table.value === res.data.tableType).label
      }
    }
  },
  methods: {
    ...mapActions('OptionalFields', [
      'deleteOptionalField',
      'fetchOptionsFields',
      'fetchOptionsFieldInfo'
    ]),
    ...mapActions('Notify', ['notifyShow']),
    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },
    itemRemove: vueWaitLoader(async function () {
      const res = await this.deleteOptionalField(this.$route.params.id)
      if (res) {
        const message = this.$t('Global.successRemoveMessage', { field: this.$t('OptionalFields.name') })
        this.notifyShow({ message })
        this.closePopup()
        await this.fetchOptionsFields({
          pageNumber: 1
        })
      }
    }, 'itemRemoveOptionalRemove')
  }
}
</script>
