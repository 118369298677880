var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "field" }, [
    _vm.type === 3
      ? _c("div", { staticClass: "dropdown-field" }, [
          _c("label", [
            _vm._v(_vm._s(_vm.$t("OptionalFields.field_dropdown_values"))),
          ]),
          _c(
            "div",
            { staticClass: "dropdown-input flexbox align-center" },
            [
              _c("input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: {
                      max: 64,
                      unique_value: [_vm.dropdownValues, "value"],
                    },
                    expression:
                      "{ max: 64, unique_value: [dropdownValues , 'value']}",
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.dropdownValue,
                    expression: "dropdownValue",
                  },
                ],
                staticClass: "txt",
                class: { "is-danger": _vm.veeErrors.has("dropdownItem") },
                attrs: {
                  type: "text",
                  name: "dropdownItem",
                  "data-vv-as": _vm.$t("OptionalFields.field_dropdown_values"),
                },
                domProps: { value: _vm.dropdownValue },
                on: {
                  keydown: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    $event.preventDefault()
                    return _vm.addDropdownValue.apply(null, arguments)
                  },
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.dropdownValue = $event.target.value
                  },
                },
              }),
              _c("Button", {
                class: _vm.dropdownValue === "" ? "c-gray" : "c-success",
                attrs: {
                  size: "medium",
                  disabled: _vm.dropdownValue === "",
                  iconName: "icon-global-add",
                  justIcon: true,
                },
                on: { click: _vm.addDropdownValue },
              }),
            ],
            1
          ),
          _c(
            "p",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.veeErrors.has("dropdownItem"),
                  expression: "veeErrors.has('dropdownItem')",
                },
              ],
              staticClass: "Form-item-help is-danger",
            },
            [_vm._v(_vm._s(_vm.veeErrors.first("dropdownItem")))]
          ),
          _c(
            "div",
            { staticClass: "writingValues flexbox wrap m-top-10" },
            _vm._l(_vm.dropdownValues, function (item, index) {
              return _c(
                "Button",
                {
                  key: index,
                  attrs: {
                    size: "medium",
                    variant:
                      "seashell btn-small width-auto m-bottom-10 m-right-10",
                    id: `writing-dropdown-value-item-${index}`,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.removeDropdownValue(item)
                    },
                  },
                },
                [
                  _c("span", [_vm._v(_vm._s(item.value))]),
                  _c("Icon", {
                    staticClass: "m-left-5",
                    attrs: {
                      name: "icon-popup-close",
                      id: `writing-dropdown-value-item-delete-${index}`,
                    },
                  }),
                ],
                1
              )
            }),
            1
          ),
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "validation flexbox" },
      [
        _vm.type === 1
          ? _c("CustomCheckbox", {
              staticClass: "m-right-10",
              attrs: {
                id: "custom-input-field-isunique",
                disabled: _vm.isEdit,
                label: _vm.$t("OptionalFields.validation_unique"),
              },
              model: {
                value: _vm.isUnique,
                callback: function ($$v) {
                  _vm.isUnique = $$v
                },
                expression: "isUnique",
              },
            })
          : _vm._e(),
        _vm.type === 1 || _vm.type === 3
          ? _c("CustomCheckbox", {
              attrs: {
                id: "custom-input-field-isrequired",
                disabled: _vm.isEdit,
                label: _vm.$t("OptionalFields.validation_required"),
              },
              model: {
                value: _vm.isRequired,
                callback: function ($$v) {
                  _vm.isRequired = $$v
                },
                expression: "isRequired",
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }