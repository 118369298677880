var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Popup",
    [
      _c("template", { slot: "title" }, [
        _vm.isEdit
          ? _c("div", [
              _vm._v(
                _vm._s(
                  _vm.$t("Global.editData", {
                    field: _vm.$t("NutritionalValues.nameWithPrefix"),
                  })
                )
              ),
            ])
          : _c("div", [
              _vm._v(
                _vm._s(
                  _vm.$t("Global.addData", {
                    field: _vm.$t("NutritionalValues.name"),
                  })
                )
              ),
            ]),
      ]),
      _c("template", { slot: "content" }, [
        _c(
          "form",
          { staticClass: "Form", attrs: { id: "Nutrition-Fields-Form" } },
          [
            _c("div", { staticClass: "Form-item required" }, [
              _c("label", { staticClass: "Form-item-label" }, [
                _vm._v(_vm._s(_vm.$t("NutritionalValues.field_name"))),
              ]),
              _c(
                "div",
                { staticClass: "control form-item" },
                [
                  _c("customTextInput", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required|max:250",
                        expression: "'required|max:250'",
                      },
                    ],
                    attrs: {
                      id: "nutritionFields-fieldname",
                      name: "customNutritionFoodName",
                      "data-vv-as": _vm.$t("NutritionalValues.field_name"),
                      error: _vm.veeErrors.has("customNutritionFoodName"),
                    },
                    model: {
                      value: _vm.customNutritionName,
                      callback: function ($$v) {
                        _vm.customNutritionName = $$v
                      },
                      expression: "customNutritionName",
                    },
                  }),
                  _c("showValidateError", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.veeErrors.has("customNutritionFoodName"),
                        expression: "veeErrors.has('customNutritionFoodName')",
                      },
                    ],
                    attrs: {
                      errorName: _vm.veeErrors.first("customNutritionFoodName"),
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            "Button",
            {
              staticClass: "uppercase",
              attrs: {
                primary: "",
                size: "large",
                type: "submit",
                variant: "full",
                form: "NutritionalValues-Fields-Form",
                isLoading: _vm.isRunAction,
                disabled: !_vm.customNutritionName,
                id: "NutritionalValues-Fields-Form-button",
              },
              on: { click: _vm.onSubmitForm },
            },
            [
              _vm.isEdit
                ? _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("Global.editData", {
                          field: _vm.$t("NutritionalValues.nameWithPrefix"),
                        })
                      )
                    ),
                  ])
                : _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("Global.addData", {
                          field: _vm.$t("NutritionalValues.name"),
                        })
                      )
                    ),
                  ]),
            ]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }