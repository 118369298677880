<template lang="pug">
Popup
    template(slot="title")
      div(v-if="isEdit") {{ $t('Global.editData', { field: $t('OptionalFields.nameWithPrefix') }) }}
      div(v-else) {{ $t('Global.addData', { field: $t('OptionalFields.name') }) }}

    template(slot="content")
      Loading(v-if="isFetchingInfo")
      form.Form(v-else
       id="Optinal-Fields-Form",
       @submit.prevent="onSubmitForm"
      )
        .Form-item.required
          label.Form-item-label
            | {{ $t('OptionalFields.field_module_name') }}
          .control.form-item-select
            Icon.icon-down-arrow(name="icon-down-arrow")
            customSelectInput(
              name="moduleTypes"
              :optionData="ModuleTypes"
              v-model="form.tableType"
              :data-vv-as="$t('OptionalFields.field_module_name')"
              :error="veeErrors.has('moduleTypes')"
              v-validate="'required'",
              id="optionalFields-module-list"
              :disabled="isEdit"
              optionIdName="optionalFields-module-list-option"
              optionName="label"
              optionKey="value"
              optionIdKey="value"
              :isValueNumber="true"
            )

            showValidateError(
              v-show="veeErrors.has('moduleTypes')"
              :errorName="veeErrors.first('moduleTypes')"
            )

        .Form-item.required
          label.Form-item-label {{ $t('OptionalFields.field_name') }}
          .control
            customTextInput(
              id="optionalFields-fieldname"
              v-model="form.columnName"
              v-validate="'required|max:50'"
              name="fieldname"
              :data-vv-as="$t('OptionalFields.field_name')"
              :error="veeErrors.has('fieldname')")

            showValidateError(
              v-show="veeErrors.has('fieldname')"
              :errorName="veeErrors.first('fieldname')"
            )

        .Form-item.required

          label.Form-item-label
            | {{ $t('OptionalFields.field_type') }}
          .control.form-item-select
            Icon.icon-down-arrow(name="icon-down-arrow")
            customSelectInput(
              name="fieldType",
              :optionData="inputTypes"
              v-model="form.columnType"
              :data-vv-as="$t('OptionalFields.field_type')"
              :error="veeErrors.has('fieldType')"
              v-validate="'required|not_in:-1'"
              id="optionalFields-fieldType-list"
              :disabled="isEdit"
              optionIdName="optionalFields-fieldType-list-option"
              optionName="label"
              optionKey="value"
              optionIdKey="value"
              :isValueNumber="true"
            )

            showValidateError(
              v-show="veeErrors.has('fieldType')"
              :errorName="veeErrors.first('fieldType')"
            )

        .Form-item
          CustomInputField(
            v-if="!isFetchingInfo"
            v-model="inputProps"
            :isEdit="isEdit"
            :type="form.columnType"
          )

    template(slot="footer")
      Button.uppercase(
        primary,
        size="large"
        type="submit"
        variant="full"
        form="Optinal-Fields-Form",
        :isLoading="isRunAction",
        :disabled="actionDisabled"
        id="Optinal-Fields-Form-button"
      )
        span(v-if="isEdit") {{ $t('Global.editData', { field: $t('OptionalFields.nameWithPrefix') }) }}
        span(v-else) {{ $t('Global.addData', { field: $t('OptionalFields.name') }) }}
</template>

<script>
import { getModules, getInputTypes } from '../data/index'
import { mapGetters, mapActions } from 'vuex'
import { vueWaitLoader } from '@/utils/baseOperations'
//
import CustomInputField from './partials/inputFields'

export default {
  name: 'OptionalFieldForm',
  props: {
    status: {
      default: 'new',
      type: String
    }
  },
  data () {
    return {
      ModuleTypes: getModules(this.$t),
      inputTypes: getInputTypes(this.$t),
      inputProps: null,
      form: {
        tableType: 1,
        columnType: -1,
        columnName: null
      }
    }
  },
  components: {
    CustomInputField
  },
  computed: {
    isEdit () {
      return this.status === 'edit'
    },
    isFetchingInfo () {
      return this.$wait.is('fetchingInfo')
    },
    isRunAction () {
      return this.$wait.is(['createOptionalField', 'updateOptionalField', 'onSubmitFormOptionalFields'])
    },
    actionDisabled () {
      return this.form.columnType === 3 && (this.inputProps.dropdownValues.length === 0) ? true : this.isRunAction
    },
    ...mapGetters('OptionalFields', ['Page'])
  },
  methods: {
    ...mapActions('Notify', ['notifyShow']),
    ...mapActions('OptionalFields', [
      'createOptionalField',
      'updateOptionalField',
      'fetchOptionsFields',
      'fetchOptionsFieldInfo'
    ]),

    closePopup () {
      this.$router.push({
        name: this.$route.matched[0].name
      })
    },

    async addNewOptionField () {
      const payload = {
        ...this.form,
        ...this.inputProps
      }
      const result = await this.createOptionalField(payload)
      if (result) {
        const message = this.$t('Global.successCreateMessage', { field: this.$t('OptionalFields.name') })
        this.notifyShow({ message })
        this.closePopup()
        this.fetchOptionsFields({
          pageNumber: 1
        })
      }
    },

    async editOptionField () {
      const payload = {
        id: this.$route.params.id,
        columnName: this.form.columnName,
        dropdownValues: this.inputProps.dropdownValues
      }
      const result = await this.updateOptionalField(payload)
      if (result) {
        const message = this.$t('Global.successUpdateMessage', { field: this.$t('OptionalFields.name') })
        this.notifyShow({ message })
        this.closePopup()
        this.fetchOptionsFields({
          pageNumber: this.Page.last
        })
      }
    },

    onSubmitForm: vueWaitLoader(async function () {
      const isValid = await this.$validator.validateAll()
      if (!isValid) return
      if (this.isEdit) {
        await this.editOptionField()
      } else {
        await this.addNewOptionField()
      }
    }, 'onSubmitFormOptionalFields'),

    async getFieldInfo () {
      const res = await this.fetchOptionsFieldInfo(this.$route.params.id)
      if (res) {
        this.Fieldinfo = res.data
        this.form.columnType = this.Fieldinfo.columnType
        this.form.columnName = this.Fieldinfo.columnName
        this.inputProps = {
          isRequired: this.Fieldinfo.isRequired,
          isUnique: this.Fieldinfo.isUnique,
          dropdownValues: this.Fieldinfo.dropdownItems || []
        }
      }
    }
  },

  async beforeMount () {
    if (this.isEdit) {
      this.$wait.start('fetchingInfo')
      await this.getFieldInfo()
      this.$wait.end('fetchingInfo')
    } else {
      this.inputProps = {
        isRequired: false,
        isUnique: false,
        dropdownValues: []
      }
    }
  }
}
</script>
