import Index from '../index'
import OptionalFieldForm from '../popup/form'
import OptionalFieldFormRemove from '../popup/remove'

import Allergen from '../allergen'

import NutritionalValues from '../nutritional-values'
import NutritionForm from '../popup/nutrition-form'
import CustomNutritionRemove from '../popup/remove-nutrition'
import NutritionFoodCopy from '../popup/copy-nutrition-food'
import NutritionDetail from '../nutrition-detail'

export default [
  {
    name: 'OptionalFields',
    path: '/optional-fields',
    component: Index,
    meta: {
      main_menu: true,
      slug: 'optional-fields',
      category: 'Governance',
      page: 'optionalFields',
      permissionKey: 'CustomColumnMenu'
    },
    children: [
      {
        name: 'NewOptionalField',
        path: 'new',
        component: OptionalFieldForm
      },
      {
        name: 'EditOptionalField',
        path: 'edit/:id',
        component: OptionalFieldForm,
        props: {
          status: 'edit'
        }
      },
      {
        name: 'DeleteOptionalField',
        path: 'remove/:id',
        component: OptionalFieldFormRemove
      }
    ]
  },
  {
    name: 'Allergen',
    path: '/optional-fields/allergen',
    component: Allergen,
    meta: {
      main_menu: false,
      page: 'optionalFields',
      permissionKey: 'CustomColumnMenu'
    }
    /* children: [
      {
        name: 'AllergenAdd',
        path: 'new',
        component: AllergenForm
      },
      {
        name: 'AllergenEdit',
        path: 'edit/:id',
        component: AllergenForm,
        props: {
          status: 'edit'
        }
      },
      {
        name: 'AllergenRemove',
        path: 'remove/:id',
        component: RemoveAllergenForm
      }
    ] */
  },
  {
    name: 'NutritionalValues',
    path: '/optional-fields/nutritional-values',
    component: NutritionalValues,
    meta: {
      main_menu: false,
      page: 'optionalFields'
    },
    children: [
      {
        name: 'NewNutrition',
        path: 'new-nutrition',
        component: NutritionForm
      },
      {
        name: 'EditNutrition',
        path: 'edit/:id',
        component: NutritionForm,
        props: {
          status: 'edit'
        }
      },
      {
        name: 'RemoveNutrition',
        path: 'remove/:id',
        component: CustomNutritionRemove
      },
      {
        path: 'item/:id/copy',
        name: 'NutritionCopy',
        component: NutritionFoodCopy
      }
    ]
  },
  {
    path: '/nutritional-values/item/:id',
    name: 'DetailNutrition',
    component: NutritionDetail
  }
]
