<template lang="pug">
.field

    .dropdown-field(v-if="type === 3")
      label {{ $t('OptionalFields.field_dropdown_values') }}
      .dropdown-input.flexbox.align-center
        input.txt(
          type="text",
          name="dropdownItem"
          @keydown.prevent.enter="addDropdownValue"
          v-validate="{ max: 64, unique_value: [dropdownValues , 'value']}",
          :class="{ 'is-danger': veeErrors.has('dropdownItem') }",
          :data-vv-as="$t('OptionalFields.field_dropdown_values')"
          v-model="dropdownValue"
        )

        Button(
          size="medium"
          :disabled="dropdownValue === ''"
          :class="dropdownValue === '' ? 'c-gray' : 'c-success'"
          iconName="icon-global-add",
          :justIcon="true",
          @click="addDropdownValue"
        )

      p.Form-item-help.is-danger(
        v-show="veeErrors.has('dropdownItem')"
        ) {{ veeErrors.first('dropdownItem') }}

      .writingValues.flexbox.wrap.m-top-10
        Button(
        size="medium"
        v-for="(item,index) in dropdownValues",
        :key="index",
        variant="seashell btn-small width-auto m-bottom-10 m-right-10"
        :id="`writing-dropdown-value-item-${index}`"
        @click="removeDropdownValue(item)"
        )
          span {{ item.value }}
          Icon.m-left-5(
            name="icon-popup-close"
            :id="`writing-dropdown-value-item-delete-${index}`"
            )

    .validation.flexbox
      CustomCheckbox.m-right-10(
        v-if="type === 1",
        id="custom-input-field-isunique",
        v-model="isUnique",
        :disabled="isEdit",
        :label="$t('OptionalFields.validation_unique')")
      CustomCheckbox(
        v-if="type === 1 || type === 3",
        id="custom-input-field-isrequired"
        v-model="isRequired",
        :disabled="isEdit",
        :label="$t('OptionalFields.validation_required')")
</template>

<script>

export default {
  name: 'InputField',
  props: {
    value: {},
    type: {
      type: Number,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isRequired: false,
      isUnique: false,
      dropdownValues: [],
      dropdownValue: ''
    }
  },
  computed: {
    values () {
      return {
        isUnique: this.isUnique,
        isRequired: this.isRequired,
        dropdownValues: this.dropdownValues
      }
    }
  },
  methods: {
    removeDropdownValue (item) {
      this.dropdownValues.splice(this.dropdownValues.indexOf(item), 1)
    },
    addDropdownValue () {
      if (this.veeErrors.has('dropdownItem')) return
      if (this.dropdownValue === '' || this.dropdownValues.findIndex(item => item.value === this.dropdownValue) > -1) return false
      this.dropdownValues.push({
        value: this.dropdownValue
      })
      this.dropdownValue = ''
    }
  },
  watch: {
    values: {
      handler (value) {
        this.$emit('input', value)
      },
      deep: true
    },
    type () {
      if (this.isEdit) return
      this.isRequired = false
      this.isUnique = false
      this.dropdownValues = []
      this.dropdownValue = ''
    }
  },
  mounted () {
    if (this.isEdit) {
      this.isRequired = this.value.isRequired
      this.isUnique = this.value.isUnique
      this.dropdownValues = this.value.dropdownValues
    }
  }
}
</script>

<style lang="scss" scoped>

 .dropdown-input {
   margin-top: 8px;
    button {
      margin-left: 20px;
      background: transparent;
      width: auto;
      height: 24px;
      min-width: 24px;
      padding: 0;
      border: none;
      &:disabled {
        background: transparent !important;
        box-shadow: none !important;
      }
    }
 }
</style>
